<template>
    <form class="ui user form" :class="{loading: saving}" @submit.prevent="saveUser">
        <div class="required field">
            <label>Name</label>
            <div class="two fields">
                <div class="field">
                    <input type="text" placeholder="First Name" v-model.trim="form.first_name" required/>
                </div>
                <div class="field">
                    <input type="text" placeholder="Last Name" v-model.trim="form.last_name" required/>
                </div>
            </div>
        </div>
        <div class="two fields">
            <div class="required field">
                <label>Gender</label>
                <select ref="genderdropdown" class="ui dropdown" v-model="form.gender" required>
                    <option value=""></option>
                    <option :value="$gender.FEMALE">{{$t("common.female")}}</option>
                    <option :value="$gender.MALE">{{$t("common.male")}}</option>
                </select>
            </div>
        </div>
        <div class="two fields">
            <div class="required field">
                <label>Email (Username)</label>
                <input type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$" autocomplete="off" v-model.trim="form.username" v-lowercase required/>
            </div>
            <div class="field" :class="{required: roles.ADMIN === form.role}">
                <label>Mobile Number</label>
                <vue-phone-number-input v-model="mobile_no" :required="roles.ADMIN === form.role" clearable no-example color="#85b7d9" default-country-code="MY" :translations="{
                    countrySelectorLabel: '',
                    countrySelectorError: '',
                    phoneNumberLabel: '',
                    example: ''
                }" @update="mobileNoChanged"/>
            </div>
        </div>
        <div class="ui section divider"></div>
        <div class="two fields">
            <div class="field">
                <label>Outlet</label>
                <div ref="outletdropdown" class="ui clearable selection dropdown">
                    <input type="hidden" :value="form.outlet" @change="changeOutlet($event.target.value)"/>
                    <i class="dropdown icon"></i>
                    <div class="default text">&nbsp;</div>
                    <div class="menu">
                        <div class="item" v-for="outlet in outlets" :key="`outlet_option_${outlet.id}`" :data-value="outlet.id">
                            {{outlet.name}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="required field">
                <label>Role</label>
                <select ref="roledropdown" class="ui dropdown" v-model="form.role" required>
                    <option value=""></option>
                    <option v-for="(role, role_code) in roles" :key="`role_option_${role_code}`" :value="role_code">{{displayRole(role_code)}}</option>
                </select>
            </div>
        </div>
        <div class="ui section divider"></div>
        <div class="two fields">
            <div class="field" :class="{required: !user.id}">
                <label>Password</label>
                <div class="ui icon input">
                    <input ref="password" :type="password_visible? 'text' : 'password'" v-model.trim="form.password" @keyup="checkCapsLock" @focus="checkCapsLock" @mousedown="checkCapsLock" @blur="unsetCapsLocked" :required="!user.id" autocomplete="new-password"/>
                    <i class="inverted black link icon" :class="{'eye': !password_visible, 'eye slash': password_visible}" @click="togglePasswordVisibility"></i>
                </div>
                <div class="ui red warning text" v-show="is_caps_locked"><i class="exclamation circle icon"></i> Caps Lock is on</div>
            </div>
        </div>
        <div class="field" v-if="user.id">
            <label>Suspended</label>
            <div>
                <toggle-button v-model="form.suspended" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" sync/>
            </div>
        </div>
    </form>
</template>

<script>
import roles from "@/assets/constants/role";

import { ToggleButton } from "vue-js-toggle-button";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    name: "user-form",
    components: {
        ToggleButton,
        VuePhoneNumberInput
    },
    props: {
        user: {
            type: Object
        }
    },
    data() {
        return {
            outlets: [],
            roles,
            form: {},
            mobile_no: "",
            is_caps_locked: false,
            password_visible: false,
            saving: false,
            toggle_checkbox: {
                color: {
                    checked: "#db2828",
                    unchecked: "#0cc2da",
                    disabled: "#cccccc"
                },
                width: 60,
                height: 28,
                labels: {
                    checked: "YES",
                    unchecked: "NO"
                }
            }
        };
    },
    mounted() {
        this.$http.get("outlets").then(({ data }) => {
            this.outlets = data;
        }).catch((error) => {
            console.error(error);

            this.$toasted.error("Unable to list outlets.", {
                duration: 3000
            });
        }).finally(() => {
            $(this.$refs.outletdropdown).dropdown();
        });

        this.$nextTick(() => {
            $(this.$refs.genderdropdown).dropdown();

            $(this.$refs.roledropdown).dropdown();
        });
    },
    methods: {
        changeOutlet(outlet) {
            if(outlet) {
                this.$set(this.form, "outlet", +outlet);
            } else {
                this.$set(this.form, "outlet", null);
            }
        },
        saveUser() {
            if(this.saving) {
                return;
            }

            if(roles.ADMIN === this.form.role && !this.form.mobile_no) {
                this.$toasted.error("Please provide a valid mobile number.", {
                    duration: 3000
                });

                return;
            }

            this.saving = true;

            let form = Object.assign({}, this.form);
            if(form.hasOwnProperty("password") && !form.password) {
                delete form.password;
            }

            if(!form.mobile_no) {
                form.mobile_no = null;
            }

            if(this.user?.id) {
                this.$http.put(`users/${this.user.id}`, form).then((response) => {
                    this.$toasted.success("User updated successfully.", {
                        duration: 3000
                    });

                    this.$emit("save:user", response.data);
                }).catch((error) => {
                    console.error(error);

                    const status_code = error.response?.status || "";
                    const message = (status_code === 409)? "Email (username) already exists." : "Failed to update user.";

                    this.$toasted.error(message, {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                this.$http.post("users", form).then((response) => {
                    this.$toasted.success("User created successfully.", {
                        duration: 3000
                    });

                    this.$emit("save:user", response.data);
                }).catch((error) => {
                    console.error(error);

                    const status_code = error.response?.status || "";
                    const message = (status_code === 409)? "Email (username) already exists." : "Failed to create user.";

                    this.$toasted.error(message, {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            }
        },
        togglePasswordVisibility() {
            this.password_visible = !this.password_visible;
        },
        checkCapsLock(event) {
            try {
                this.is_caps_locked = !!event.getModifierState("CapsLock");
            } catch(error) {
            }
        },
        unsetCapsLocked() {
            this.is_caps_locked = false;
        },
        displayRole(role) {
            return `${role.charAt(0).toUpperCase()}${role.toLowerCase().slice(1)}`;
        },
        mobileNoChanged(data) {
            if(data.isValid) {
                this.form.mobile_no = data.countryCallingCode + data.nationalNumber;
            } else {
                this.form.mobile_no = null;
            }
        }
    },
    watch: {
        user: {
            deep: true,
            immediate: true,
            handler() {
                this.form = Object.assign({}, !this.isBlankObject(this.user)? this.user : {
                    username: "",
                    password: "",
                    first_name: "",
                    last_name: "",
                    gender: "",
                    outlet: "",
                    role: "",
                    mobile_no: null
                });

                if(this.form.mobile_no) {
                    this.mobile_no = "+" + this.form.mobile_no;
                }

                this.$nextTick(() => {
                    if(this.form.gender) {
                        $(this.$refs.genderdropdown).dropdown("set selected", this.form.gender);
                    } else {
                        $(this.$refs.genderdropdown).dropdown("clear");
                    }

                    if(this.form.outlet) {
                        $(this.$refs.outletdropdown).dropdown("set selected", this.form.outlet);
                    } else {
                        $(this.$refs.outletdropdown).dropdown("clear");
                    }

                    if(this.form.role) {
                        $(this.$refs.roledropdown).dropdown("set selected", this.form.role);
                    } else {
                        $(this.$refs.roledropdown).dropdown("clear");
                    }
                });
            }
        },
        saving() {
            this.$emit("change:saving", this.saving);
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.user.form {
    ::v-deep {
        .country-selector .country-selector__input {
            border-radius: 0;
            border-right: none;
        }

        .country-selector__country-flag,
        .country-selector__toggle,
        .input-tel__clear {
            top: 50%;
            transform: translate(0, -50%);
        }

        .country-selector .country-selector__input,
        .input-tel .input-tel__input {
            padding-top: .67857143em;
            height: auto;
            min-height: 0;
        }

        .input-tel__clear > span:not(.input-tel__clear__effect) {
            top: 0;
        }
    }
}
</style>